import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Modal = () => {
  const { modals } = useSelector((state) => state.modal);

  return (
    <>
      {modals.map((modal) => (
        <Snackbar
          key={modal.id}
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={100} // Adjust this value as needed
          color="primary"
          message={modal.msg}
        ></Snackbar>
      ))}
    </>
  );
};

export default Modal;
