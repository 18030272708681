import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";
import useFetch from "../../hooks/useFetch";
import { setIsLoading } from "../../features/modalSlice";
import { loginService } from "../../services/authServices";
import { fullLogo, guestLogo, loginIcon } from "../../assets";
import { useAuth0 } from "@auth0/auth0-react";
import "./Login.css"; // Import the CSS file for styling
import Snackbar from "@mui/material/Snackbar";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";

import { useRouter } from "../../routes/hooks";

import { bgGradient } from "../../theme/css";

import Logo from "../../components/logo";
import Iconify from "../../components/iconify";

const Login = () => {
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const customFetch = useFetch();
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    // Using react leaflet to get user location and set it to the form
    navigator.geolocation.getCurrentPosition((position) => {
      // Get Reverse location from lat and lng
      fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
      )
        .catch((err) => {
          console.log(err);
        })
        .then((res) => {
          console.log(res);
          res.json();
        })
        .then((data) => {
          setForm((form) => ({
            ...form,
            location: "",
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }));
        });
      setForm((form) => ({
        ...form,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }));
    });
  }, []);

  const loginHandler = async (email, password) => {
    dispatch(setIsLoading(true));
    const data = await customFetch(loginService, { ...form, email, password });
    if (data) dispatch(login(data));
    dispatch(setIsLoading(false));
  };
  const router = useRouter();

  const guestHandler = () => {
    dispatch(login({ id: "guest", isGuest: true }));
    router.push("/");
  };

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      console.log(user);
      loginHandler(user.email, user.sub);
    }
  }, [user, isAuthenticated, isLoading]);

  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    router.push("/dashboard");
  };

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_4.jpg",
        }),
        height: 1,
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            height: 500,
            width: 400,
            marginTop: "80px",
          }}
        >
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            <Logo />
          </Typography>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={() => loginWithRedirect()}
            sx={{
              mt: "50%",
            }}
          >
            Login or Signup
          </LoadingButton>
          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="outlined"
            onClick={guestHandler}
          >
            Continue as guest
          </LoadingButton>
        </Card>
      </Stack>
    </Box>
  );
};

export default Login;
